/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    margin: 0;
    padding: 0;
}


.text-center {
  text-align: center;
}

.hidden {
  display: none !important;
}


/**
 * Basic styling
 */
body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-weight: 300;
    color: $text-color;
    background-color: $background-color;
    -webkit-text-size-adjust: 100%;
}

* {
    box-sizing: border-box;
}

s {
    display: inline;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img, video {
    max-width: 100%;
    vertical-align: middle;
}


/**
 * Figures
 */
figure > img {
    display: block;
}

figure.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

/* Inserting this collapsed row between two flex items will make 
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
}



/**
 * Links
 */
a {
    color: $brand-color;
    text-decoration: none;

    &:visited {
        color: darken($brand-color, 15%);
    }

    &:hover {
        color: $text-color;
        // text-decoration: underline;
        border-bottom: 1px solid $text-color;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}

.lang-fa blockquote {
  border-left: 0;
  padding-right: inherit;
  border-right: 4px solid $grey-color-light;
  padding-right: $spacing-unit / 2;
  font-style: inherit;
}



/**
 * Code formatting
 */
pre,
code {
    font-size: 15px;
    border: 1px solid $grey-color-light;
    border-radius: 3px;
    background-color: white;
}

/**
 * Code formatting
 */
h1 pre, h1 code,
h2 pre, h2 code,
h3 pre, h3 code,
h4 pre, h4 code {
    font-size: inherit;
}

code {
    padding: 1px 5px;
}

pre {
    padding: 8px 12px;
    overflow-x: scroll;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
    }
}



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        padding-right: $spacing-unit / 2;
        padding-left: $spacing-unit / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

@font-face {
	font-family: 'Damion';
	src: url(fonts/Damion_gdi.woff);
}

@font-face {
    font-family: 'Ubuntu';
    src: url(fonts/Ubuntu_gdi.woff);
}
@font-face {
    font-family: 'Ubuntu Light';
    src: url(fonts/Ubuntu-Light_gdi.woff);
}
//@font-face {
    //font-family: 'Ubuntu Mono';
    //src: url(fonts/UbuntuMono-Regular_gdi.woff);
//}
@font-face {
  font-family: Mononoki;
  src: url(fonts/mononoki-Regular.woff);
}

@font-face {
    font-family: Gandom;
    src: url(fonts/Gandom-FD.eot);
    src: url(fonts/Gandom-FD?#iefix) format('embedded-opentype'),
            url(fonts/Gandom-FD.woff) format('woff'),
            url(fonts/Gandom-FD.woff2) format('woff2'),
            url(fonts/Gandom-FD.ttf) format('truetype');
}
        
@font-face {
    font-family: Sahel;
    src: url(fonts/Sahel.eot);
    src: url(fonts/Sahel.eot?#iefix) format('embedded-opentype'),
            url(fonts/Sahel.woff) format('woff'),
            url(fonts/Sahel.ttf) format('truetype');
    font-weight: normal;
}
        
@font-face {
    font-family: Sahel;
    src: url(fonts/Sahel-Bold.eot);
    src: url(fonts/Sahel-Bold.eot?#iefix) format('embedded-opentype'),
            url(fonts/Sahel-Bold.woff) format('woff'),
            url(fonts/Sahel-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: Sahel;
    src: url(fonts/Sahel-Black.eot);
    src: url(fonts/Sahel-Black.eot?#iefix) format('embedded-opentype'),
            url(fonts/Sahel-Black.woff) format('woff'),
            url(fonts/Sahel-Black.ttf) format('truetype');
    font-weight: 900;
}
