html {
  scroll-behavior: smooth;
}

body {
  background: #FFF7EC;
  height: auto;
  padding-bottom: 5rem;
}

.share-page {
  padding: 10px 50px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin: 50px 0;

  a {
    margin-left: 1rem;
  }
}

header {
  width: 100%;
  text-align: center;
  padding-top: $spacing-unit;

  img {
    // border-radius: 50%;
    margin: $spacing-unit auto;
    // border: 1px solid #F5DFC3;
  }
  h1 {
    font-size: 4rem;
    font-family: $head-font-family;
    text-transform: lowercase;
  }
  h1 a, & > a {
    text-decoration: none;
    color: $text-color !important;

    &:hover {
      border: none;
    }
  }

  nav {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  nav a {
    margin: 0 1rem;
  }

  a:visited {
    color: $brand-color;
  }
}

.page-heading {
  text-align: center;
  font-style: italic;
  width: 20%;
  height: 1px;
  border: 1px dotted rgba(0, 0, 0, 0.1);

  margin: 2rem auto 5rem;
}

.post-list, .post-header {
  list-style: none;
  margin-left: 0;
  margin-bottom: 5rem;

  h2, h1 {
    text-align: center;
  }

  .post-meta {
    font-size: $small-font-size;
    display: block;
    text-align: center;
    margin-bottom: 2rem;

    span {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}

article p img {
  margin: 2rem auto;
  display: block;

  &.inline {
    display: inline;
    margin: 0.1rem;
  }
}

strong {
  font-weight: bold;
}

.post article h1 {
  font-weight: bold;
}

.post-list li {
  margin-top: 100px;
}

.post-list li:first-of-type {
  margin-top: 0;
}

.post-list h2 {
  font-size: 1.5rem;
}

.post article h2 {
  font-size: 1.3rem;
}

.post-content {
  position: relative;
}

.post-content [id] {
  position: relative;

  &:hover .heading-link {
    opacity: 1;
  }
}

.lang-fa {
  font-family: $base-font-family;
  direction: rtl !important;
  .post-content {
    text-align: right;
    font-size: 1.05em;
    line-height: 1.718em;
  }
  .share-page {
    direction: ltr;
  }

  .bibliography {
    direction: ltr;
    text-align: left;
  }
}

.post-content .heading-link {
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;

  left: -30px;
  top: 10px;

  padding-right: 30px;

  background: url(/img/link.svg) no-repeat;
  background-size: contain;

  opacity: 0;

  transition: opacity 0.3s ease;

  &:hover {
    border: none;
  }
}

.post-content .footnote + .heading-link {
  display: none;
}

.footnotes .heading-link {
  left: -40px;
  top: 5px;
}

article .image-caption {
  display: block;
  text-align: center;
  font-size: 10pt;
  color: $grey-color;
  margin-top: -1.5rem;
}

.rss-subscribe {
  text-align: center;
}

hr {
  border: none;
  border-top: 1px solid lighten($grey-color, 20%);
  margin: 1.5rem 0;
}

.channel {
  & > a::before {
    content: 'BroadcastChannels rock';
    color: white;
    position: absolute;
    top: 250px;
    left: calc(50% - 83px);
    font-size: 15px;
    text-shadow: 0 0 1px white;
  }
}

.centered {
  margin: 2rem auto;
  display: block;
}

.art .image-details {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px black;
  font-family: monospace;
  text-align: left;

  transition: opacity 0.2s;
}

.art .image-details.lang-fa {
  left: none;
  right: 10px;
  text-align: right;
  font-family: $base-font-family;
}

.art .post-content:hover .image-details {
  opacity: 0;
}

.side-anchors {
  margin: 1rem;
  vertical-align: middle;

  ul {
    list-style-type: '- ';
    margin-left: 1.5rem;
  }

  .meta {
    font-size: 0.8rem;
    margin-right: 0.5rem;
  }
}

.notice {
  background-color: #80c4d5;
  padding: 1rem 2rem;
  border-radius: 3px;

  p {
    margin: 0;
  }
}

#browser {
  display: inline-block;
}
